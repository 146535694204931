
import { Toast } from "antd-mobile";
export function MessageError(message, error) {
    if (message) {
        return Toast.show({
            icon: 'success',
            content: message,
        })
    }

    if (error) {
        return Toast.show({
            icon: 'fail',
            content: error,
        })
    }
}
