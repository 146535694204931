import {
    AUTHENTICATE_USER_STARTED,
    AUTHENTICATE_USER_SUCCESS,
    AUTHENTICATE_USER_FAILURE,
    FORGOT_PASSWORD_STARTED,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILURE,
    UPDATE_PASSWORD_STARTED,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_FAILURE,
    RESET_PASSWORD_STARTED,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
    SEND_RESET_PASSWORD_STARTED,
    SEND_RESET_PASSWORD_SUCCESS,
    SEND_RESET_PASSWORD_FAILURE,
    LOGOUT,
    REMOVE_ERROR_MESSAGE
} from "../../constants/authenticate/authenticate";

import API from "../../../utils/API";


export const authenticateUser = (token, username, password) => {
    return (dispatch) => {
        dispatch(authenticateUserStarted());

        API.post(`/api/login`, {
            token,
            username,
            password
        })
            .then((res) => {
                dispatch(authenticateUserSuccess(res.data));
            })
            .catch((err) => {
                dispatch(authenticateUserFailure(err.message));
            });
    };
};

const authenticateUserStarted = () => ({
    type: AUTHENTICATE_USER_STARTED,
});

const authenticateUserSuccess = (data) => ({
    type: AUTHENTICATE_USER_SUCCESS,
    payload: { data },
});

const authenticateUserFailure = (error) => ({
    type: AUTHENTICATE_USER_FAILURE,
    payload: { error },
});


export function logout() {
    API.post("/api/logout");
    return { type: LOGOUT, payload: null };
}

export const sendResetPassword = (username, tokenReCaptcha) => {
    return (dispatch) => {
        dispatch(sendResetPasswordStarted());
        API.post(`/api/send-reset-password`,
            { username, tokenReCaptcha })
            .then((res) => {
                dispatch(sendResetPasswordSuccess());
            })
            .catch((err) => {
                catchError(err, dispatch, sendResetPasswordFailure);
            }).finally(() => {
                setTimeout(function () {
                    dispatch(removeErrorMessage());
                }, 1000);
            });
    };
}

const sendResetPasswordStarted = () => ({
    type: SEND_RESET_PASSWORD_STARTED,
});

const sendResetPasswordSuccess = () => ({
    type: SEND_RESET_PASSWORD_SUCCESS,
});

const sendResetPasswordFailure = (error) => ({
    type: SEND_RESET_PASSWORD_FAILURE,
    payload: { error },
});


export const resetPassword = (token, password, tokenReCaptcha) => {
    return (dispatch) => {
        dispatch(resetPasswordStarted());
        API.post(`/api/reset-password`,
            { token, password, tokenReCaptcha })
            .then((res) => {
                dispatch(resetPasswordSuccess());
            })
            .catch((err) => {
                catchError(err, dispatch, resetPasswordFailure)
            }).finally(() => {
                setTimeout(function () {
                    dispatch(removeErrorMessage());
                }, 1000);
            });
    };
}

const resetPasswordStarted = () => ({
    type: RESET_PASSWORD_STARTED,
});

const resetPasswordSuccess = () => ({
    type: RESET_PASSWORD_SUCCESS,
});

const resetPasswordFailure = (error) => ({
    type: RESET_PASSWORD_FAILURE,
    payload: { error },
});


export const forgotPassword = (email) => {
    return (dispatch) => {
        dispatch(forgotPasswordStarted());
        API.post(`/forgot-password`,
            email)
            .then((res) => {
                dispatch(forgotPasswordSuccess());
            })
            .catch((err) => {
                dispatch(forgotPasswordFailure(err.message));
            }).finally(() => {
                setTimeout(function () {
                    dispatch(removeErrorMessage());
                }, 3000);
            });
    };

}



const forgotPasswordStarted = () => ({
    type: FORGOT_PASSWORD_STARTED,
});

const forgotPasswordSuccess = () => ({
    type: FORGOT_PASSWORD_SUCCESS,
});

const forgotPasswordFailure = (error) => ({
    type: FORGOT_PASSWORD_FAILURE,
    payload: { error },
});

export const updatePassword = (password, newPassword) => {
    return (dispatch) => {
        dispatch(updatePasswordStarted());
        API.post(`/api/update-password`,
            { password, newPassword })
            .then((res) => {
                dispatch(updatePasswordSuccess(res.data));
            })
            .catch((err) => {
                catchError(err, dispatch, updatePasswordFailure);
            }).finally(() => {
                setTimeout(function () {
                    dispatch(removeErrorMessage());
                }, 1000);
            });
    };

}

const updatePasswordStarted = () => ({
    type: UPDATE_PASSWORD_STARTED,
});

const updatePasswordSuccess = () => ({
    type: UPDATE_PASSWORD_SUCCESS,
});

const updatePasswordFailure = (error) => ({
    type: UPDATE_PASSWORD_FAILURE,
    payload: { error },
});


const logoutUser = () => ({
    type: LOGOUT
})


const removeErrorMessage = () => ({
    type: REMOVE_ERROR_MESSAGE,
})


//Handle catch error
//logout use when unauthorized
//display error message and remove message after 3 seconds
const catchError = (error, dispatch, nextFunction) => {
    var message = error.message
    if (error.response) {
        message = error.response.data.message
        if (error.response.status === 401) {
            dispatch(logoutUser());
        }
    }

    dispatch(nextFunction(message));
};