
export const GET_APPOINTMENTS_STARTED = "GET_APPOINTMENTS_STARTED"
export const GET_APPOINTMENTS_SUCCESS = "GET_APPOINTMENTS_SUCCESS"
export const GET_APPOINTMENTS_FAILURE = "GET_APPOINTMENTS_FAILURE"

export const GET_APPOINTMENT_DETAIL_STARTED = "GET_APPOINTMENT_DETAIL_STARTED"
export const GET_APPOINTMENT_DETAIL_SUCCESS = "GET_APPOINTMENT_DETAIL_SUCCESS"
export const GET_APPOINTMENT_DETAIL_FAILURE = "GET_APPOINTMENT_DETAIL_FAILURE"

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"
