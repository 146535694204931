import React from "react";
import { Tag, Tooltip } from "antd";
import {
    QuestionCircleFilled,
    QuestionCircleTwoTone,
    CheckCircleTwoTone,
    EnvironmentTwoTone,
    CloseCircleTwoTone,
    StopTwoTone,
    HomeTwoTone,
    WarningTwoTone,
    CheckCircleFilled,
    EnvironmentFilled,
    CloseCircleFilled,
    StopFilled,
    HomeFilled,
    WarningFilled
} from "@ant-design/icons";
export const AppointmentStatus = props => {
    const { status, noMargin = false, displayText = true } = props;

    let getColor = (status) => {
        switch (status) {
            case "Pending":
                return {
                    color: "#faad14",
                    singleIcon: <QuestionCircleFilled style={{ color: "#faad14" }} />,
                    icon: <QuestionCircleTwoTone twoToneColor="#faad14" />
                }
            case "Confirmed":
                return {
                    color: '#108ee9',
                    singleIcon: <CheckCircleFilled style={{ color: "#1677ff" }} />,
                    icon: <CheckCircleTwoTone twoToneColor="#108ee9" />
                }
            case "Checked-in":
                return {
                    color: '#722ed1',
                    singleIcon: <EnvironmentFilled style={{ color: "#722ed1" }} />,
                    icon: <EnvironmentTwoTone twoToneColor="#722ed1" />
                }
            case "Cancel":
                return {
                    color: '#8c8c8c',
                    singleIcon: <CloseCircleFilled style={{ color: "#8c8c8c" }} />,
                    icon: <CloseCircleTwoTone twoToneColor="#8c8c8c" />
                }
            case "Completed":
                return {
                    color: '#87d068',
                    singleIcon: <CheckCircleFilled style={{ color: "#52c41a" }} />,
                    icon: <CheckCircleTwoTone twoToneColor="#87d068" />
                }
            case "No-show":
                return {
                    color: '#fa541c',
                    singleIcon: <StopFilled style={{ color: "#fa541c" }} />,
                    icon: <StopTwoTone twoToneColor="#fa541c" />
                }
            case "Walked-in":
                return {
                    color: '#b37feb',
                    singleIcon: <HomeFilled style={{ color: "#b37feb" }} />,
                    icon: <HomeTwoTone twoToneColor="#b37feb" />
                }
            default: return {
                color: '#FF0000',
                singleIcon: <WarningFilled style={{ color: "#FF0000" }} />,
                icon: <WarningTwoTone twoToneColor="#FF0000" />
            }
        }
    }

    const color = getColor(status)
    if (displayText) {
        return <Tag
            color={color.color}

            style={noMargin
                ? { margin: "0px", padding: "0px 3px", borderRadius: "5px" }
                : { borderRadius: "5px" }
            }
        >
            {color.icon}  {status}
        </Tag>
    }

    return (
        <Tooltip title={status}>

            {color.singleIcon}

        </Tooltip>
    );

};
