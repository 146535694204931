
export const GET_HISTORY_SALES_STARTED = "GET_HISTORY_SALES_STARTED"
export const GET_HISTORY_SALES_SUCCESS = "GET_HISTORY_SALES_SUCCESS"
export const GET_HISTORY_SALES_FAILURE = "GET_HISTORY_SALES_FAILURE"


export const GET_HISTORY_SALES_BY_DATE_STARTED = "GET_HISTORY_SALES_BY_DATE_STARTED"
export const GET_HISTORY_SALES_BY_DATE_SUCCESS = "GET_HISTORY_SALES_BY_DATE_SUCCESS"
export const GET_HISTORY_SALES_BY_DATE_FAILURE = "GET_HISTORY_SALES_BY_DATE_FAILURE"

export const GET_HISTORY_TIPS_STARTED = "GET_HISTORY_TIPS_STARTED"
export const GET_HISTORY_TIPS_SUCCESS = "GET_HISTORY_TIPS_SUCCESS"
export const GET_HISTORY_TIPS_FAILURE = "GET_HISTORY_TIPS_FAILURE"

export const GET_HISTORY_TIPS_BY_DATE_STARTED = "GET_HISTORY_TIPS_BY_DATE_STARTED"
export const GET_HISTORY_TIPS_BY_DATE_SUCCESS = "GET_HISTORY_TIPS_BY_DATE_SUCCESS"
export const GET_HISTORY_TIPS_BY_DATE_FAILURE = "GET_HISTORY_TIPS_BY_DATE_FAILURE"

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"
