import {
    GET_CALENDAR_APPOINTMENTS_STARTED,
    GET_CALENDAR_APPOINTMENTS_SUCCESS,
    GET_CALENDAR_APPOINTMENTS_FAILURE,
    REMOVE_ERROR_MESSAGE
} from "../../constants/calendar/calendar";
import { LOGOUT } from "../../constants/authenticate/authenticate";

import API from "../../../utils/API";
import dayjs from "dayjs";

export const getCalendarAppointments = () => {
    return (dispatch) => {
        dispatch(getCalendarAppointmentsStarted());
        API.get(`/api/appointments/get-appointment-calendar?month=${dayjs().format('YYYY-MM')}`)
            .then((res) => {
                dispatch(getCalendarAppointmentsSuccess(res.data));
            })
            .catch((err) => {
                catchError(err, dispatch, getCalendarAppointmentsFailure)
            }).finally(() => {
                setTimeout(function () {
                    dispatch(removeErrorMessage());
                }, 1000);
            });
    };
}

const getCalendarAppointmentsStarted = () => ({
    type: GET_CALENDAR_APPOINTMENTS_STARTED,
});

const getCalendarAppointmentsSuccess = (data) => ({
    type: GET_CALENDAR_APPOINTMENTS_SUCCESS,
    payload: {
        appointments: data
    },
});

const getCalendarAppointmentsFailure = (error) => ({
    type: GET_CALENDAR_APPOINTMENTS_FAILURE,
    payload: { error },
});

const logoutUser = () => ({
    type: LOGOUT
})


const removeErrorMessage = () => ({
    type: REMOVE_ERROR_MESSAGE,
})


//Handle catch error
//logout use when unauthorized
//display error message and remove message after 3 seconds
const catchError = (error, dispatch, nextFunction) => {
    var message = error.message
    if (error.response) {
        message = error.response.data.message
        if (error.response.status === 401) {
            dispatch(logoutUser());
        }
    }

    dispatch(nextFunction(message));
};