
export const GET_GENERAL_INFO_STARTED = "GET_GENERAL_INFO_STARTED"
export const GET_GENERAL_INFO_SUCCESS = "GET_GENERAL_INFO_SUCCESS"
export const GET_GENERAL_INFO_FAILURE = "GET_GENERAL_INFO_FAILURE"

export const UPLOAD_AVATAR_STARTED = "UPLOAD_AVATAR_STARTED"
export const UPLOAD_AVATAR_SUCCESS = "UPLOAD_AVATAR_SUCCESS"
export const UPLOAD_AVATAR_FAILURE = "UPLOAD_AVATAR_FAILURE"

export const REMOVE_ERROR_MESSAGE = "REMOVE_ERROR_MESSAGE"
