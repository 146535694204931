import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCalendarAppointments } from '../../redux/actions/calendar/calendar'
import Loading from '../../component/Common/Loading/Loading'
import { MessageError } from '../../component/Common/MessageError/MessageError'
import { CalendarPickerView, Modal } from 'antd-mobile'
import { Space, Button } from 'antd'
import { useNavigate } from 'react-router'

const statusColor = {
    "Pending": "#faad14",
    "Confirmed": '#108ee9',
    "Checked-in": '#722ed1',
    "Cancel": '#8c8c8c',
    "Completed": '#87d068',
    "No-show": '#fa541c'
}

const CalendarAppointments = () => {
    const [month, setMonth] = useState(dayjs().format('YYYY-MM'))
    const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'))
    const dispatch = useDispatch()
    const nagivate = useNavigate()
    const { error, message, loading, appointments } = useSelector(state => state.calendar)

    const min = dayjs(month).startOf('month').toDate();
    const max = dayjs(month).endOf('month').toDate();


    const onChangeDate = (value) => {
        const dateString = dayjs(value).format('YYYY-MM-DD')
        setDate(dateString)

        const dateData = appointments.find(appointmentByDate => appointmentByDate._id === dateString)
        if (dateData) {
            Modal.confirm({
                content: renderDayAppointments(dateData),
                title: <div style={{ fontSize: "18px", fontWeight: "500" }}>{dayjs(dateString).format('ddd, MMM D, YYYY')}</div>,
                cancelText: 'Close',
                confirmText: 'View details',
                onConfirm: () => nagivate(`/?date=${dateString}`)
            })
        };
    }

    const countTotalAppointments = (date) => {
        let count = 0

        const dateAppointments = appointments.find(appointment => appointment._id === dayjs(date).format('YYYY-MM-DD'))
        let isToday = false
        if (dateAppointments?._id) {
            isToday = dayjs().isSame(dayjs(dateAppointments._id, "YYYY-MM-DD"), 'day');
        }

        if (dateAppointments) {
            for (let appointment of dateAppointments.appointments) {
                count += appointment.count
            }
            return <span style={{ fontSize: "14px", fontWeight: "bold", color: !isToday ? "#fa8c16" : "#ffffff" }}>{count}</span>
        } else {
            return null
        }
    }

    const renderDayAppointments = (dateData) => {
        return (
            <div style={{ padding: "10px" }}>
                {dateData.appointments.map((appointment) => {
                    return <div style={{ color: `${statusColor[appointment.status]}`, textAlign: 'center', fontSize: "18px", marginBottom: "5px" }} key={`${appointment}-${appointment.status}`}>
                        {appointment.status}: {appointment.count}
                    </div>
                })}
            </div>

        );
    }

    useEffect(() => {
        dispatch(getCalendarAppointments(month))
    }, [dispatch, month])

    useEffect(() => {
        MessageError(message, error)
    }, [error, message])

    return (
        <div style={{ padding: "10px 0px" }}>
            <div >
                <CalendarPickerView
                    style={{ height: "max-content" }}
                    className='calendar-picker'
                    min={min}
                    max={max}
                    allowClear={false}
                    value={dayjs(date).toDate()}
                    onChange={onChangeDate}
                    renderBottom={(date) => countTotalAppointments(date)}
                    title={
                        <Space.Compact size='large' style={{ width: "100%" }}>
                            <Button
                                style={{ width: "50%" }}
                                onClick={() => setMonth(dayjs().format('YYYY-MM'))}
                                type={dayjs().format('YYYY-MM') === month ? 'primary' : 'default'}
                            >
                                This month
                            </Button>
                            <Button
                                style={{ width: "50%" }}
                                onClick={() => setMonth(dayjs().add(1, "month").format("YYYY-MM"))}
                                type={dayjs().add(1, "month").format("YYYY-MM") === month ? 'primary' : 'default'}
                            >
                                Next month
                            </Button>
                        </Space.Compact>
                    }
                />
            </div >
            <Loading loading={loading} />
        </div>
    )
}

export default CalendarAppointments