import React, { useEffect } from 'react'
import HistoryDateSelect from '../../../component/History/HistoryDateSelect'
import { useDispatch, useSelector } from 'react-redux'

import { MessageError } from '../../../component/Common/MessageError/MessageError'
import { getHistoryTips } from '../../../redux/actions/history/history'
import { formatRangeDate } from '../../../utils/utility'
import { Table } from 'antd'
import { Tabs } from 'antd-mobile'
import TipByDate from './tipByDate'

const Tips = (props) => {

    const dispatch = useDispatch()
    var { tips, message, error, loading } = useSelector(state => state.history)
    const { business, staff } = useSelector(state => state.generalInfo)
    const { from, to } = props.historyFromTo

    const columns = [
        {
            title: 'Service',
            dataIndex: '_id',
            key: '_id',
            width: 140
        },
        // {
        //     title: 'Sub.',
        //     dataIndex: 'grossSales',
        //     key: 'grossSales',
        //     sorter: (a, b) => (a.grossSales > b.grossSales ? 1 : -1),
        //     defaultSortOrder: 'descend',
        //     render: (value) => (Number(value).toFixed(2))
        // },
        // {
        //     title: 'To. tips',
        //     dataIndex: 'totalTip',
        //     key: 'totalTip',
        //     render: (value) => (Number(value).toFixed(2))
        // },
        {
            title: 'Total Tips',
            dataIndex: 'tipCommission',
            key: 'tipCommission',
            render: (value) => (Number(value).toFixed(2))
        },
    ];

    useEffect(() => {
        const { fromWithHour, toWithHour } = formatRangeDate(from, to, business?.timeZone)
        dispatch(getHistoryTips(fromWithHour, toWithHour))
    }, [dispatch, from, to, business?.timeZone])

    useEffect(() => {
        MessageError(message, error)
    }, [error, message])

    return (
        <div style={{ padding: "20px 0px", width: "100%" }}>
            <HistoryDateSelect historyFromTo={props.historyFromTo} />
            <div style={{ margin: "10px 0px" }}>
                <h3 style={{ margin: "0px" }}>Tip Commission: {staff?.tipCommission ? staff.tipCommission : 100}%</h3>
            </div>
            <Tabs defaultActiveKey='1'>
                <Tabs.Tab title='By Services' key='1'>
                    <Table
                        // key={Math.random()}
                        loading={loading}
                        size='small'
                        rowKey='_id'
                        dataSource={tips ? tips.filter(item => item.tipCommission > 0) : []}
                        columns={columns}
                        // scroll={{ y: window.innerHeight - 100 }}
                        pagination={false}
                        sticky={true}
                        style={{ margin: "10px 0px" }}
                        summary={(pageData) => {
                            // let grossSales = 0
                            // let totalTip = 0
                            let tipCommission = 0
                            pageData.forEach(item => {
                                // grossSales += item.grossSales
                                // totalTip += item.totalTip
                                tipCommission += item.tipCommission
                            })
                            if (pageData.length > 0) {
                                return (
                                    <Table.Summary fixed="top" >
                                        <Table.Summary.Row style={{ fontWeight: "bold" }}>
                                            <Table.Summary.Cell>Total</Table.Summary.Cell>
                                            {/* <Table.Summary.Cell>{Number(grossSales).toFixed(2)}</Table.Summary.Cell> */}
                                            {/* <Table.Summary.Cell>{Number(totalTip).toFixed(2)}</Table.Summary.Cell> */}
                                            <Table.Summary.Cell>{Number(tipCommission).toFixed(2)}</Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </Table.Summary>
                                )
                            }
                        }}
                    />
                </Tabs.Tab>
                <Tabs.Tab title='By Date' key='2'>
                    <TipByDate historyFromTo={props.historyFromTo} />
                </Tabs.Tab>
            </Tabs>
        </div>
    )
}

export default Tips