import React from 'react';
import { Mask, SpinLoading } from 'antd-mobile';

const Loading = ({ loading }) => {
    return (
        <>
            <Mask visible={loading}>
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)',
                    background: 'white',
                    borderRadius: '15px',
                    width: '70px',
                    height: '70px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '-50px',
                }}>
                    <SpinLoading color='primary' style={{ '--size': '48px' }} />
                </div>
            </Mask>
        </>
    );
};

export default Loading;
