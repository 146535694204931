import {
    GET_APPOINTMENTS_STARTED,
    GET_APPOINTMENTS_SUCCESS,
    GET_APPOINTMENTS_FAILURE,
    GET_APPOINTMENT_DETAIL_STARTED,
    GET_APPOINTMENT_DETAIL_SUCCESS,
    GET_APPOINTMENT_DETAIL_FAILURE,
    REMOVE_ERROR_MESSAGE
} from "../../constants/appointment/appointment";

const initialState = {
    appointments: [],
    appointmentDetail: null,
    staff: null,
    staffHour: null,
    holidays: [],
    specialHours: [],
    loading: false,
    error: null,
    message: null,
};

export default function appointment(state = initialState, action) {
    switch (action.type) {

        case GET_APPOINTMENT_DETAIL_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };

        case GET_APPOINTMENT_DETAIL_SUCCESS:
            return {
                ...state,
                appointmentDetail: action.payload.appointmentDetail,
                error: null,
                loading: false,
            };

        case GET_APPOINTMENT_DETAIL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };

        case GET_APPOINTMENTS_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };

        case GET_APPOINTMENTS_SUCCESS:
            return {
                ...state,
                appointments: action.payload.appointments,
                staff: action.payload.staff,
                staffHour: action.payload.staffHour,
                holidays: action.payload.holidayAndSpecialHour.filter((item) => item.isSpecialHour === false),
                specialHours: action.payload.holidayAndSpecialHour.filter((item) => item.isSpecialHour === true),
                error: null,
                loading: false,
            };

        case GET_APPOINTMENTS_FAILURE:
            // console.log(action.payload.error);
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };

        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
            };
        default:
            return { ...state };
    }
}
