import React, { useRef, useState } from "react";
import { Button, Space } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons"
import dayjs from "dayjs";
import { CalendarPicker } from "antd-mobile";

const DateSelect = (props) => {
    const { date } = props
    const [visible, setVisible] = useState(false)

    const isToday = dayjs(date).isSame(new Date(), "day")
    const min = dayjs().startOf('month').toDate();
    const max = dayjs().add(1, 'month').endOf('month').toDate();
    const ref = useRef(null)
    return (
        <Space.Compact size="large">
            <Button onClick={() => props.handleChangeDate(dayjs(date).add(-1, "days").format("YYYY-MM-DD"))} icon={<LeftOutlined />}></Button>
            <Button type={isToday ? "primary" : "default"} onClick={() => props.handleChangeDate(dayjs().format("YYYY-MM-DD"))} >Today</Button>
            <Button
                onClick={() => {
                    setVisible(true)
                    ref.current?.jumpTo(page => ({
                        year: page.year,
                        month: page.month,
                    }))
                }}
            >
                {dayjs(date).format("YYYY-MM-DD")}
            </Button>
            <CalendarPicker
                ref={ref}
                title="Select Date"
                selectionMode='single'
                visible={visible}
                confirmText={"Close"}
                closeOnMaskClick
                allowClear={false}
                value={dayjs(date).toDate()}
                onClose={() => {
                    setVisible(false)
                }}
                onChange={value => {
                    props.handleChangeDate(dayjs(value).format("YYYY-MM-DD"))
                    setVisible(false)
                }}
                min={min}
                max={max}
            />
            <Button onClick={() => props.handleChangeDate(dayjs(date).add(1, "days").format("YYYY-MM-DD"))} icon={<RightOutlined />}></Button>
        </Space.Compact>

    );
};

export default DateSelect;
