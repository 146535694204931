import {
    GET_GENERAL_INFO_STARTED,
    GET_GENERAL_INFO_SUCCESS,
    GET_GENERAL_INFO_FAILURE,
    UPLOAD_AVATAR_STARTED,
    UPLOAD_AVATAR_SUCCESS,
    UPLOAD_AVATAR_FAILURE,
    REMOVE_ERROR_MESSAGE
} from "../../constants/generalInfo/generalInfo";

import { LOGOUT } from "../../constants/authenticate/authenticate";

import API from "../../../utils/API";

export const uploadAvatar = (avatarFormData) => {
    return (dispatch) => {
        dispatch(uploadAvatarStarted());
        API.post(`/api/general-info/upload-avatar-image`, avatarFormData)
            .then((res) => {
                dispatch(uploadAvatarSuccess(res.data));
            })
            .catch((err) => {
                catchError(err, dispatch, uploadAvatarFailure)
            }).finally(() => {
                setTimeout(function () {
                    dispatch(removeErrorMessage());
                }, 1000);
            });
    };
}

const uploadAvatarStarted = () => ({
    type: UPLOAD_AVATAR_STARTED,
});

const uploadAvatarSuccess = (data) => ({
    type: UPLOAD_AVATAR_SUCCESS,
    payload: {
        staff: data,
    },
});

const uploadAvatarFailure = (error) => ({
    type: UPLOAD_AVATAR_FAILURE,
    payload: { error },
});

export const getGeneralInfo = () => {
    return (dispatch) => {
        dispatch(getGeneralInfoStarted());
        API.get(`/api/general-info/get-general-info`)
            .then((res) => {
                dispatch(getGeneralInfoSuccess(res.data));
            })
            .catch((err) => {
                catchError(err, dispatch, getGeneralInfoFailure)
            }).finally(() => {
                setTimeout(function () {
                    dispatch(removeErrorMessage());
                }, 1000);
            });
    };
}

const getGeneralInfoStarted = () => ({
    type: GET_GENERAL_INFO_STARTED,
});

const getGeneralInfoSuccess = (data) => ({
    type: GET_GENERAL_INFO_SUCCESS,
    payload: {
        staff: data.staff,
        staffHour: data.staffHour,
        business: data.business,
        menu: data.menu,
    },
});

const getGeneralInfoFailure = (error) => ({
    type: GET_GENERAL_INFO_FAILURE,
    payload: { error },
});

const logoutUser = () => ({
    type: LOGOUT
})


const removeErrorMessage = () => ({
    type: REMOVE_ERROR_MESSAGE,
})


//Handle catch error
//logout use when unauthorized
//display error message and remove message after 3 seconds
const catchError = (error, dispatch, nextFunction) => {
    var message = error.message
    if (error.response) {
        message = error.response.data.message
        if (error.response.status === 401) {
            dispatch(logoutUser());
        }
    }

    dispatch(nextFunction(message));
};