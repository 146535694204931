import { Button, Form, Input, NoticeBar } from 'antd-mobile'
import { EyeInvisibleOutline, EyeOutline } from 'antd-mobile-icons'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MessageError } from '../../component/Common/MessageError/MessageError'
import { updatePassword } from '../../redux/actions/authenticate/authenticate'

const ChangePassword = () => {

    const { loading, error, message, user } = useSelector(state => state.authenticate)
    const dispatch = useDispatch()
    const [form] = Form.useForm()

    const [visible, setVisible] = useState(false)
    const onFinish = async () => {
        const { password, newPassword } = form.getFieldsValue()

        dispatch(updatePassword(password, newPassword))
    };

    useEffect(() => {
        MessageError(message, error)
    }, [error, message])


    return (
        <div style={{ width: '100%' }}>
            <Form
                style={{ width: '100%', textAlign: 'left' }}
                form={form}
                // layout='horizontal'
                mode='card'
                onFinish={onFinish}
                footer={
                    <Button block type='submit' color='primary' loading={loading} size='large' shape='rounded'>
                        Change Password
                    </Button>
                }
            >
                <Form.Item
                    name='password'
                    label='Password'
                    rules={[{ required: true, message: 'Password is required!' }]}
                    extra={
                        <div >
                            {!visible ? (
                                <EyeInvisibleOutline onClick={() => setVisible(true)} />
                            ) : (
                                <EyeOutline onClick={() => setVisible(false)} />
                            )}
                        </div>
                    }
                >

                    <Input
                        placeholder='Current password'
                        clearable
                        type={visible ? 'text' : 'password'}
                    />



                </Form.Item>
                <Form.Item
                    name='newPassword'
                    label='New password'
                    rules={[
                        { required: true, message: 'New password is required!' },
                        { pattern: /^(?=.*[A-Z])(?=.*\d).{6,}$/, message: "Password must contain at least 6 characters long and contains at least 1 uppercase letter, 1 lowercase letter and 1 number" }
                    ]}
                    extra={
                        <div >
                            {!visible ? (
                                <EyeInvisibleOutline onClick={() => setVisible(true)} />
                            ) : (
                                <EyeOutline onClick={() => setVisible(false)} />
                            )}
                        </div>
                    }
                >
                    <Input
                        placeholder='New password'
                        clearable
                        type={visible ? 'text' : 'password'}
                    />
                </Form.Item>
                <Form.Item
                    name='reTypeNewPassword'
                    label='Re-type new password'
                    rules={[
                        { required: true, message: 'Re-type password is required!' },
                        {
                            validator: (_, value) => {
                                const newPassword = form.getFieldValue('newPassword');
                                if (value && value !== newPassword) {
                                    return Promise.reject('Passwords do not match!');
                                }
                                return Promise.resolve();
                            }
                        }
                    ]}
                    extra={
                        <div >
                            {!visible ? (
                                <EyeInvisibleOutline onClick={() => setVisible(true)} />
                            ) : (
                                <EyeOutline onClick={() => setVisible(false)} />
                            )}
                        </div>
                    }
                >
                    <Input
                        placeholder='Re-type new password'
                        clearable
                        type={visible ? 'text' : 'password'}
                    />
                </Form.Item>
            </Form>

            {user.isRequiredChangePassword &&
                <NoticeBar
                    wrap
                    content="Please update your password to continue using the app."
                    color='alert' />
            }

        </div>
    )
}

export default ChangePassword