import React, { useEffect } from 'react'
// import HistoryDateSelect from '../../../component/History/HistoryDateSelect'
import { useDispatch, useSelector } from 'react-redux'

import { MessageError } from '../../../component/Common/MessageError/MessageError'
import { getHistoryTipsByDate } from '../../../redux/actions/history/history'
import { formatRangeDate } from '../../../utils/utility'
import { Table } from 'antd'

const TipByDate = (props) => {

    const dispatch = useDispatch()
    var { tipsByDate, message, error, loading } = useSelector(state => state.history)
    const { business } = useSelector(state => state.generalInfo)
    const { from, to } = props.historyFromTo

    const columns = [
        {
            title: 'Date',
            dataIndex: '_id',
            key: '_id',
            width: 140
        },
        // {
        //     title: 'Sub.',
        //     dataIndex: 'grossSales',
        //     key: 'grossSales',
        //     sorter: (a, b) => (a.grossSales > b.grossSales ? 1 : -1),
        //     defaultSortOrder: 'descend',
        //     render: (value) => (Number(value).toFixed(2))
        // },
        // {
        //     title: 'To. tips',
        //     dataIndex: 'totalTip',
        //     key: 'totalTip',
        //     render: (value) => (Number(value).toFixed(2))
        // },
        {
            title: 'Total Tips',
            dataIndex: 'tipCommission',
            key: 'tipCommission',
            render: (value) => (Number(value).toFixed(2))
        },
    ];

    useEffect(() => {
        const { fromWithHour, toWithHour } = formatRangeDate(from, to, business?.timeZone)
        dispatch(getHistoryTipsByDate(fromWithHour, toWithHour))
    }, [dispatch, from, to, business?.timeZone])

    useEffect(() => {
        MessageError(message, error)
    }, [error, message])

    return (
        <Table
            // key={Math.random()}
            loading={loading}
            size='small'
            rowKey='_id'
            dataSource={tipsByDate ? tipsByDate.filter(item => item.tipCommission > 0) : []}
            columns={columns}
            pagination={false}
            sticky={true}
            scroll={{ y: 240 }}
            style={{ margin: "10px 0px" }}
            summary={(pageData) => {
                // let grossSales = 0
                // let totalTip = 0
                let tipCommission = 0
                pageData.forEach(item => {
                    // grossSales += item.grossSales
                    // totalTip += item.totalTip
                    tipCommission += item.tipCommission
                })
                if (pageData.length > 0) {
                    return (
                        <Table.Summary fixed="top">
                            <Table.Summary.Row style={{ fontWeight: "bold", }}>
                                <Table.Summary.Cell>Total</Table.Summary.Cell>
                                {/* <Table.Summary.Cell>{Number(grossSales).toFixed(2)}</Table.Summary.Cell> */}
                                {/* <Table.Summary.Cell>{Number(totalTip).toFixed(2)}</Table.Summary.Cell> */}
                                <Table.Summary.Cell>{Number(tipCommission).toFixed(2)}</Table.Summary.Cell>
                            </Table.Summary.Row>
                        </Table.Summary>
                    )
                }
            }}
        />
    )
}

export default TipByDate