import { AutoCenter, Grid, List, NavBar } from 'antd-mobile'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { getAppointmentDetail } from '../../redux/actions/appointment/appointment'
import queryString from 'query-string'
import { MessageError } from '../../component/Common/MessageError/MessageError'
import Loading from '../../component/Common/Loading/Loading'
import { AppointmentStatus } from '../../component/Appointments/AppointmentStatus'
import DateCard from '../../component/AppointmentDetail/DateCard'
import './AppointmentDetail.css'
import dayjs from 'dayjs'
import { convertMinsToHrsMins } from '../../utils/utility'
import { HeartTwoTone } from '@ant-design/icons'

const AppointmentDetail = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const { error, message, loading, appointmentDetail } = useSelector(state => state.appointment)

    useEffect(() => {
        const queryObject = queryString.parse(location.search)
        dispatch(getAppointmentDetail(queryObject.aid))
    }, [dispatch, location.search])

    useEffect(() => {
        MessageError(message, error)
    }, [error, message])

    if (!appointmentDetail || loading) {
        return <Loading />
    }

    return (
        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100vh', zIndex: 100, background: "white" }}>
            <div className="top-nav"  >
                <NavBar onBack={() => navigate(-1)}>Appointment #{appointmentDetail.number}</NavBar>
            </div>
            <div style={{ padding: "20px" }}>
                <List  style={{
                    '--padding-left': "0px",
                    '--padding-right': "0px",
                }}>
                    <List.Item
                        style={{ borderRight: "1px solid rgb(238, 240, 242)" }}
                        prefix={<DateCard date={appointmentDetail.date} />}
                        description={<AppointmentStatus status={appointmentDetail.status} />}
                    >
                        <h2 style={{ fontWeight: "bold", fontSize: "20px", }}>{appointmentDetail.clientName}</h2>
                    </List.Item>
                </List>

                <div style={{ marginTop: 30, textAlign: "start" }}>
                    <AutoCenter>
                        {appointmentDetail.services.map((service, index) => {
                            return (
                                <Grid key={index} columns={3} gap={20} style={{ marginBottom: 20 }}>
                                    <Grid.Item style={{ fontWeight: "bold", fontSize: "16px" }}> {dayjs(service.start, "HH:mm").format('hh:mm a')} </Grid.Item>
                                    <Grid.Item span={2}>
                                        <div style={{ fontWeight: "bold", fontSize: "16px" }}>{service.name}</div>
                                        <div style={{ fontSize: "15px" }}>
                                            <span>{convertMinsToHrsMins(service.duration)}</span> -
                                            {service.staffName === service.preferredStaff &&
                                                <span> <HeartTwoTone twoToneColor="#eb2f96" /></span>
                                            }
                                            <span> {service.staffName}</span>
                                        </div>
                                    </Grid.Item>
                                </Grid>
                            )
                        })}
                    </AutoCenter>
                </div>
                {appointmentDetail.notes && <div style={{ fontSize: "16px", marginTop: 20 }}>
                    <span style={{ fontWeight: "bold" }}>Note: </span>
                    <span>{appointmentDetail.notes}</span>
                </div>}
            </div>
        </div>
    )
}

export default AppointmentDetail