import React from 'react'
import dayjs from "dayjs";
const DateCard = (props) => {
    const date = dayjs(props.date, "YYYY-MM-DD")

    const month = date.format("MMM")
    const dayOfMonth = date.format("DD")
    const dayOfWeek = date.format("ddd")
    // const year = date.format("YYYY")
    // console.log(dayOfWeek)

    return (
        <div className={"date-card " + month.toLowerCase()} style={{ borderRadius: "8px" }}>
            <p className={"month "}>{month}</p>
            <p className={"day-of-month"}>{dayOfMonth}</p>
            <p className={"day-of-week"}>{dayOfWeek}</p>
        </div>
    )
}

export default DateCard