import { Button, CalendarPicker, Picker } from 'antd-mobile'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { convertRangeToString, getDateRange } from '../../utils/utility'
import {
    useLocation,
    useNavigate,
} from 'react-router-dom'

const selectValue = [
    [
        { label: 'Today', value: 'today' },
        { label: 'Yesterday', value: 'yesterday' },
        { label: 'This week', value: 'this-week' },
        { label: 'Last week', value: 'last-week' },
        { label: 'This month', value: 'this-month' },
        { label: 'Last month', value: 'last-month' },
        { label: 'Range', value: 'range' },
    ]
]

const HistoryDateSelect = (props) => {
    const { from, to } = props.historyFromTo

    const [pickerVisible, setPickerVisible] = useState(false)
    const [calendarVisible, setCalendarVisible] = useState(false)
    const [value, setValue] = useState(convertRangeToString(from, to))
    const navigate = useNavigate();
    const location = useLocation();


    const min = dayjs().add(-2, 'month').startOf('month').toDate()
    const max = dayjs().endOf('month').toDate()

    const handleChangeRange = ([from, to]) => {
        navigate(`${location.pathname}?historyFrom=${dayjs(from).format('YYYY-MM-DD')}&historyTo=${dayjs(to).format('YYYY-MM-DD')}`)
    }

    const handleChangeDate = (value) => {
        setValue(value)
        if (value === "range") {
            setCalendarVisible(true)
        } else {
            let { from, to } = getDateRange(value);
            navigate(`${location.pathname}?historyFrom=${from}&historyTo=${to}`)
        }
    }

    return (
        <div style={{ width: "100%" }}>
            <Button
                size='medium'
                block
                color='primary'
                onClick={() => {
                    setPickerVisible(true)
                }}
            >
                {selectValue[0].find(item => item.value === value)?.label || "Today"}
            </Button>
            {
                value === "range" &&
                <Button
                    style={{ marginTop: "10px" }}
                    size='medium'
                    block
                    onClick={() => {
                        setCalendarVisible(true)
                    }}
                >
                    from: {from} - to: {to}
                </Button>
            }
            <Picker
                columns={selectValue}
                visible={pickerVisible}
                onClose={() => {
                    setPickerVisible(false)
                }}
                value={[value]}
                onConfirm={v => {
                    handleChangeDate(v[0])
                }}
            />
            <CalendarPicker
                visible={calendarVisible}
                allowClear={false}
                title="Select Date"
                value={[dayjs(from).toDate(), dayjs(to).toDate()]}
                selectionMode='range'
                onClose={() => setCalendarVisible(false)}
                closeOnMaskClick
                confirmText={"Close"}
                onChange={handleChangeRange}
                min={min}
                max={max}
            />
        </div>
    )
}

export default HistoryDateSelect