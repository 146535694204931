import React, { useEffect, useState } from 'react'
import { CurrentTimeIndicator } from '../../component/Appointments/CurrentTimeIndicator'
import dayjs from 'dayjs'
import HourCanva from '../../component/Appointments/HourCanva'
import DragDropAppointmentList from '../../component/Appointments/DragDropAppointmentList'
import "./Appointments.css"
import DateSelect from '../../component/Appointments/DateSelect'
import { useLocation, useNavigate } from 'react-router'
import queryString from "query-string";
import { useDispatch, useSelector } from 'react-redux'
import { getAppointments } from '../../redux/actions/appointment/appointment'
import Loading from '../../component/Common/Loading/Loading'
import { MessageError } from '../../component/Common/MessageError/MessageError'
import { PullToRefresh } from 'antd-mobile'

const blockTimeHeight = 30

const Appointments = () => {
	const dispatch = useDispatch()
	const location = useLocation()
	const [timeZone, setTimeZone] = useState('');
	let queryObject = queryString.parse(location.search);

	const [state, setState] = useState({
		popUpVisible: false,
		date: queryObject.date ? dayjs(queryObject.date).format("YYYY-MM-DD") : dayjs().format("YYYY-MM-DD"),
	});

	const navigate = useNavigate()

	const { loading, message, error } = useSelector(state => state.appointment)

	const {
		date,
	} = state;

	const isToday = dayjs().isSame(
		dayjs(date).format("YYYY-MM-DD"),
		"day"
	);

	const handleChangeDate = (value) => {

		queryObject = { ...queryObject, date: value };
		const dayOfWeek = dayjs(value, "YYYY-MM-DD").day();

		setState({
			...state,
			date: value, dayOfWeek
		});
		const searchString = queryString.stringify(queryObject);
		// this.props.getAppointments(value);
		navigate({
			path: "/",
			search: searchString,
		});
	};

	useEffect(() => {
		if (date && timeZone) {
			dispatch(getAppointments(date, timeZone))
		}

	}, [date, dispatch, timeZone]);


	useEffect(() => {
		const business = localStorage.getItem("business") ? JSON.parse(localStorage.getItem("business")) : null
		if (business) {
			setTimeZone(business.timeZone);
		}
		// eslint-disable-next-line
	}, []);


	useEffect(() => {
		MessageError(message, error)
	}, [error, message])

	if (loading) return <Loading />
	return (
		<div style={{ width: "100%", minHeight: "100%" }}>
			<PullToRefresh
				onRefresh={() => dispatch(getAppointments(date, timeZone))}
			>
				<div
					style={{ paddingBottom: "20px", paddingTop: "20px", position: "sticky", top: 46, left: 0, zIndex: 50, background: "white", width: "100%" }}
				>

					<DateSelect
						handleChangeDate={handleChangeDate}
						date={date}
					/>

				</div>



				<div
					style={{
						position: "relative",
						width: "100%",
						paddingBottom: "30px",
					}}
				>
					{isToday ? <CurrentTimeIndicator /> : <div
						className='new-default-scroll-view'
						style={{ top: blockTimeHeight * 12 }}
					></div>}
					<div
						style={{
							width: "100%",
							position: "absolute",
						}}
					>
						<HourCanva
							blockTimeHeight={blockTimeHeight}
							date={date}
						/>
					</div>
					<div style={{ position: "relative" }}>
						<DragDropAppointmentList
							blockTimeHeight={blockTimeHeight}
							navigate={navigate}
						/>
					</div>
				</div>

			</PullToRefresh>
		</div>
	)
}

export default Appointments