import { combineReducers } from "redux"
import authenticate from "./authenticate/authenticate"
import appointment from "./appointment/appointment"
import generalInfo from "./generalInfo/generalInfo"
import calendar from "./calendar/calendar"
import history from "./history/history"

const appReducer = combineReducers({
    authenticate,
    generalInfo,
    appointment,
    calendar,
    history
})

const initialState = appReducer({}, {})


const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT' || action.type === "LOGOUT_ALL_USER") {
        return appReducer(initialState, action)
    }
    return appReducer(state, action)
}


export default rootReducer