import {
    GET_GENERAL_INFO_STARTED,
    GET_GENERAL_INFO_SUCCESS,
    GET_GENERAL_INFO_FAILURE,
    UPLOAD_AVATAR_STARTED,
    UPLOAD_AVATAR_SUCCESS,
    UPLOAD_AVATAR_FAILURE,
    REMOVE_ERROR_MESSAGE
} from "../../constants/generalInfo/generalInfo";

const initialState = {
    menu: [],
    staff: null,
    staffHour: null,
    business: null,
    loading: false,
    error: null,
    message: null,
};

export default function generalInfo(state = initialState, action) {
    switch (action.type) {

        case UPLOAD_AVATAR_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };

        case UPLOAD_AVATAR_SUCCESS:
            return {
                ...state,
                staff: action.payload.staff,
                error: null,
                loading: false,
            };

        case UPLOAD_AVATAR_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };

        case GET_GENERAL_INFO_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };

        case GET_GENERAL_INFO_SUCCESS:
            return {
                ...state,
                staff: action.payload.staff,
                staffHour: action.payload.staffHour,
                business: action.payload.business,
                menu: action.payload.menu,
                error: null,
                loading: false,
            };

        case GET_GENERAL_INFO_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };

        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
            };
        default:
            return { ...state };
    }
}
