// import { Button, Form, Input, Row, Col, Alert } from 'antd';
// import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useState, useCallback, useEffect, useRef } from "react"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import {
    Form,
    Input,
    Button,
    NavBar,
} from 'antd-mobile'

import { EyeInvisibleOutline, EyeOutline } from 'antd-mobile-icons'
import { useDispatch, useSelector } from "react-redux";
import { authenticateUser } from "../../redux/actions/authenticate/authenticate";
import { MessageError } from "../../component/Common/MessageError/MessageError";
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
const Login = () => {
    const location = useLocation();
    const formRef = useRef();
    const passwordRef = useRef(null);

    const { executeRecaptcha } = useGoogleReCaptcha();
    const { loading, error, message } = useSelector(state => state.authenticate)
    const dispatch = useDispatch()

    const [visible, setVisible] = useState(false)

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log("Execute recaptcha not yet available");
            return;
        }

        return await executeRecaptcha();
    }, [executeRecaptcha]);


    const onFinish = async (values) => {
        const { username, password } = values
        const token = await handleReCaptchaVerify();

        dispatch(authenticateUser(token, username, password))
    };
    useEffect(() => {
        // Assuming you're using react-router, you can use useLocation() hook to get the current URL
        // If you're not using react-router, you can use window.location.search
        const params = queryString.parse(location.search);

        if (params.email) {
            formRef.current.setFieldsValue({ username: params.email })
            passwordRef.current.focus();
        }
    }, [location.search]);

    useEffect(() => {
        MessageError(message, error)
    }, [error, message])

    return <>
        <div className="top-nav"  >
            <NavBar back={null} >
                <div style={{ textAlign: "center", marginTop: "15px" }}>
                    {/* <img src='dash-logo-long.png' alt="Logo" height="50px" /> */}
                    <h2 style={{ color: "#1677ff" }}>Dash Booking</h2>
                </div>
            </NavBar>
        </div>
        <h2 style={{ marginTop: "10%" }}>Log In</h2>
        <Form
            layout='horizontal'
            ref={formRef}
            onFinish={onFinish}
            mode="card"
            footer={
                <Button block type='submit' color='primary' loading={loading} size='large' shape='rounded'>
                    Login
                </Button>
            }
        >
            <Form.Item
                name='username'
                label='Username'
                rules={[{ required: true, message: 'Username is required!' }, { type: 'email', message: 'Invalid email!' }]}
            >
                <Input type="email" placeholder='Username' />
            </Form.Item>
            <Form.Item
                name='password'
                label='Password'
                rules={[{ required: true, message: 'Password is required!' }]}
                extra={
                    <div >
                        {!visible ? (
                            <EyeInvisibleOutline onClick={() => setVisible(true)} />
                        ) : (
                            <EyeOutline onClick={() => setVisible(false)} />
                        )}
                    </div>
                }
            >

                <Input
                    placeholder='Password'
                    ref={passwordRef}
                    clearable
                    type={visible ? 'text' : 'password'}
                />



            </Form.Item>

        </Form >
        <a href="/forgot-password">Forgot your password?</a>
    </>
};
export default Login;