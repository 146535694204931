import React from 'react';
import 'antd/dist/reset.css';
import './App.css';
import MenuContainer from './layouts/Navigation/MenuContainer';
import { useSelector } from 'react-redux'
import Login from './pages/Login/Login';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/ResetPassword/ResetPassword';

const unauthenticateRoutes = createBrowserRouter([
    {
        path: "/forgot-password",
        element: <ForgotPassword />,
    },
    {
        path: "/reset-password",
        element: <ResetPassword />,
    },
    {
        path: "/*",
        element: <Login />,
    },
]);


const authenticateRoutes = createBrowserRouter([
    {
        path: "/*",
        element: <MenuContainer />
    },
]);


const App = () => {
    const { user } = useSelector((state) => state.authenticate)
    const isUserAuthenticated = user.authenticated || false

    if (!isUserAuthenticated) {
        return <div className="App">
            <GoogleReCaptchaProvider reCaptchaKey='6Lc4nnMpAAAAAL1cwrw5XgnskJDi7_yczkF0eEfT'>
                <RouterProvider router={unauthenticateRoutes} />
            </GoogleReCaptchaProvider>

        </div>
    }

    return <div className="App">
        <RouterProvider router={authenticateRoutes} />
    </div>
}



export default App;