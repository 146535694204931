import { Button, Form, Input, NavBar } from "antd-mobile";
import { useDispatch, useSelector } from "react-redux";
import { sendResetPassword } from "../../redux/actions/authenticate/authenticate";
import { MessageError } from "../../component/Common/MessageError/MessageError";
import { useCallback, useEffect } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const ForgotPassword = () => {

    const { executeRecaptcha } = useGoogleReCaptcha();
    const { loading, error, message } = useSelector(state => state.authenticate)
    const dispatch = useDispatch()
    const [form] = Form.useForm()

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log("Execute recaptcha not yet available");
            return;
        }

        return await executeRecaptcha();
    }, [executeRecaptcha]);

    const onFinish = async () => {
        const tokenReCaptcha = await handleReCaptchaVerify();
        dispatch(sendResetPassword(form.getFieldValue('username'), tokenReCaptcha))
    }

    useEffect(() => {
        MessageError(message, error)
    }, [error, message])

    return (
        <>
            <div className="top-nav"  >
                <NavBar back={null}> <div style={{ textAlign: "center", marginTop: "15px" }}>
                    {/* <img src='dash-logo-long.png' alt="Logo" height="50px" /> */}
                    <h2 style={{ color: "#1677ff" }}>Dash Booking</h2>
                </div></NavBar>
            </div>
            <h2 style={{ marginTop: "10%" }}>Forgot Password</h2>
            <Form
                style={{ marginTop: "30px" }}
                form={form}
                // layout='horizontal'
                requiredMarkStyle='none'
                onFinish={onFinish}
                // mode='card'
                footer={
                    <Button block type='submit' color='primary' loading={loading} size='large' shape='rounded'>
                        Send Reset Password
                    </Button>
                }
            >
                <Form.Item
                    label="Username"
                    name="username"
                    rules={[{ required: true, message: 'Please input your Username!' }]}
                >
                    <Input placeholder="Please enter your username" style={{ '--text-align': 'center' }} />
                </Form.Item>

            </Form>
            <a className="login-form-forgot" href="/">
                Back To Login
            </a>
        </>
    )
}
export default ForgotPassword;