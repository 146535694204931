import React, { useState } from 'react';
import { Space, Tag } from 'antd-mobile';
import { Card } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { CheckCircleOutline, } from 'antd-mobile-icons';
import Loading from '../../component/Common/Loading/Loading';
import { uploadAvatar } from '../../redux/actions/generalInfo/generalInfo';
import { Upload, Modal } from 'antd';
import API from '../../utils/API';
import ImgCrop from 'antd-img-crop';
const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
const Profile = () => {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    // const [avatarImageFile, setAvatarImageFile] = useState(null);

    const [previewTitle, setPreviewTitle] = useState('');
    const { staff, loading } = useSelector(state => state.generalInfo)

    const [fileList, setFileList] = useState(staff?.staffImage ? [
        {
            uid: '-1',
            name: staff?.name,
            status: 'done',
            url: staff?.staffImage,
        }
    ] : []);

    const dispatch = useDispatch();
    // const fileInput = useRef();

    // const onChange = ({ fileList: newFileList }) => {
    //     setFileList(newFileList);
    // };



    // const handleFileChange = (event) => {
    //     const file = event.target.files[0];
    //     if (file) {
    //         setAvatarImageFile(file);
    //         const url = URL.createObjectURL(file);
    //         setPreviewImage(url);
    //     }
    // };

    const handleUpload = ({ file }) => {
        let avatarFormData = new FormData();
        avatarFormData.append("file", file);
        dispatch(uploadAvatar(avatarFormData));
    };

    // const handleUploadAvatar = () => {
    //     let avatarFormData = new FormData();
    //     if (avatarImageFile) {
    //         avatarFormData.append("file", avatarImageFile);
    //         dispatch(uploadAvatar(avatarFormData));
    //     }
    // };

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    if (!staff || loading) {
        return <Loading />;
    }

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(staff.name);
    };

    const handleRemoveImage = async () => {
        try {
            await API.patch('/api/general-info/delete-avatar-image', { _id: staff._id })
            setFileList([]);
        } catch (error) {
            console.log('Delete Image Error!')
        }
    }


    const handleCancel = () => setPreviewOpen(false);


    const { bookingOnline, checkIn, description, title } = staff


    return (
        <div style={{ padding: "40px 0px", display: "flex", alignItems: "center", flexDirection: "column", width: "100%", background: "#fafbfc", overflowY: "auto" }}>

            <>
                <ImgCrop rotationSlider>
                    <Upload
                        onPreview={handlePreview}
                        listType="picture-card"
                        fileList={fileList}
                        onChange={onChange}
                        accept="image/*"
                        beforeUpload={file => {
                            handleUpload({ file });
                            return false; // return false so file is not auto uploaded
                        }}
                        onRemove={handleRemoveImage}

                    >
                        {fileList.length < 1 && '+ Upload'}

                    </Upload>
                </ImgCrop>


            </>

            {/* {previewImage && (
                <Space gap={5} style={{ marginTop: "5px" }}>
                    <Button
                        color='danger'
                        size='small'
                        fill='outline'
                        onClick={() => {
                            setPreviewImage(null)
                            setAvatarImageFile(null);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        color='primary'
                        size='small'
                        fill='outline'
                        onClick={handleUploadAvatar}
                    >
                        Save
                    </Button>
                </Space>
            )} */}

            {title && <div style={{ marginTop: 10, fontSize: 18, fontWeight: 500 }}>{title}</div>}
            <Space style={{ marginTop: 10 }}>
                {bookingOnline && <Tag round color='#2db7f5'><CheckCircleOutline /> Booking</Tag>}
                {checkIn && <Tag round color='#87d068'><CheckCircleOutline /> Walk-in</Tag>}
            </Space>
            <div style={{ marginTop: 20 }}></div>
            {description && <Card size='small' title='Description' style={{ marginTop: 10, width: "100%" }}>{description}</Card>}
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img
                    alt="example"
                    style={{
                        width: '100%',
                    }}
                    src={previewImage}
                />
            </Modal>
        </div>
    );
};

export default Profile;
