import React from "react";
import { Row, Col } from "antd";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";
dayjs.extend(customParseFormat)
var isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)
var isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
dayjs.extend(isSameOrAfter)

const HourCanva = ({ blockTimeHeight, date }) => {

    const HOUR_ARRAY = [
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
    ];

    const { holidays, specialHours } = useSelector(state => state.appointment)
    const { staffHour } = useSelector(state => state.generalInfo)

    const getShifts = () => {
        const dayOfWeek = dayjs(date, "YYYY-MM-DD").day();

        let shifts = staffHour
            ? staffHour[dayOfWeek].shifts
            : []
        if (holidays.length > 0) {
            shifts = []
        }
        if (specialHours.length > 0) {
            shifts = specialHours[0].shifts
        }
        return shifts
    }

    const checkWorkingHour = (time, shifts) => {
        let isWorking = false;
        const format = "HH:mm";
        var hour = dayjs(time, format);
        if (shifts && shifts.length > 0) {
            shifts.forEach((shift) => {
                var open = dayjs(shift.open, format);
                var close = dayjs(shift.close, format);
                if (hour.isBetween(open, close, undefined, "[)")) {
                    isWorking = true;
                }
            });
        }

        return isWorking;
    }

    const getQuaterOfHour = (hour) => {
        const quaters = ["00", "15", "30", "45"];

        const quaterOfHours = quaters.map((quater) => {
            const hourArray = hour.split(":");
            hourArray[1] = quater;
            const quaterHour = hourArray.join(":");
            return quaterHour;
        });

        return quaterOfHours;
    };

    const shifts = getShifts()

    return HOUR_ARRAY.map((hour) => {
        return (
            <Row
                key={hour}
                className="noselect"
                style={{
                    height: blockTimeHeight * 4 + "px",
                    position: 'relative'
                }}
            >
                <Col flex="40px">{dayjs(hour, "HH:mm").format("h a")}</Col>
                <Col
                    flex="1 1"
                    style={{ borderLeft: "1px solid #dee3e7" }}
                >
                    {" "}
                    {getQuaterOfHour(hour).map(
                        (quater, index) => {
                            const isWorking = checkWorkingHour(
                                quater,
                                shifts
                            );

                            // const isWorking = true
                            return (
                                <div
                                    key={index}
                                    className={
                                        isWorking
                                            ? "new-quater-of-hour noselect"
                                            : "new-quater-of-hour noselect staff-not-available"
                                    }
                                    style={{
                                        height: blockTimeHeight,
                                        lineHeight:
                                            blockTimeHeight + "px",
                                        borderTop:
                                            index === 0
                                                ? "1px solid #dee3e7"
                                                : "1px solid #eef0f2",
                                    }}
                                />
                            );
                        }
                    )}
                </Col>
            </Row>
        );
    });
}

export default HourCanva;