import API from "../../../utils/API";
import {
    AUTHENTICATE_USER_FAILURE,
    AUTHENTICATE_USER_SUCCESS,
    AUTHENTICATE_USER_STARTED,
    FORGOT_PASSWORD_STARTED,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILURE,
    UPDATE_PASSWORD_STARTED,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_FAILURE,
    RESET_PASSWORD_STARTED,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
    SEND_RESET_PASSWORD_STARTED,
    SEND_RESET_PASSWORD_SUCCESS,
    SEND_RESET_PASSWORD_FAILURE,
    LOGOUT,
    REMOVE_ERROR_MESSAGE,
} from "../../constants/authenticate/authenticate";

import Cookies from "js-cookie";

const user = JSON.parse(localStorage.getItem("user")) || {
    authenticated: false,
    isRequiredChangePassword: false,
    permissions: []
};

const initialState = {
    user: user,
    loading: false,
    error: null,
    message: null,
    component: "",
};

/**
 * Handle Login and Logout
 * User object with token will be stored in localStorage
 * @param {*} state
 * @param {*} action
 */
export default function authenticate(state = initialState, action) {
    switch (action.type) {

        case AUTHENTICATE_USER_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };

        case AUTHENTICATE_USER_SUCCESS:
            //Make API to server and set the new USer as an example
            const { staffAccount, business, staff } = action.payload.data;
            var newUser = {
                authenticated: true,
                isRequiredChangePassword: staffAccount.isRequiredChangePassword,
                permissions: staffAccount.permissions,
            };
            if (business) {
                localStorage.setItem("business", JSON.stringify(business));
            }
            if (staff) {
                localStorage.setItem("staff", JSON.stringify(staff));
            }
            Cookies.set("staff", JSON.stringify(newUser), { expires: 90 });
            localStorage.setItem("user", JSON.stringify(newUser));



            //set API header for server side request authentication
            // API.defaults.headers.common["Authorization"] = `Bearer ${token}`;

            return {
                ...state,
                user: newUser,
                error: null,
                loading: false,
            };

        case AUTHENTICATE_USER_FAILURE:
            return {
                ...state,
                loading: false,
                error: "Incorrect Email or Password!",
            };
        //User logout
        //remove all the cookies
        case LOGOUT:
            Cookies.remove("staff");
            localStorage.clear();
            API.defaults.headers.common["Authorization"] = `Bearer `;
            let user_logout = {
                authenticated: false,
                isRequiredChangePassword: false,
                permissions: []
            };
            return {
                ...state,
                user: user_logout,
                isRequiredActivation: false,
                loading: false,
                error: null
            };
        case FORGOT_PASSWORD_STARTED:
            return {
                ...state,
                loading: true,
                message: null,
                error: null,
                component: "ForgotPassword",
            };
        case FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                message:
                    "A reset password instruction was sent to your email if it is in our database!",
            };
        case FORGOT_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                message:
                    "A reset password instruction was sent to your email if it is in our database!",
            };
        case UPDATE_PASSWORD_STARTED:
            return {
                ...state,
                loading: true,
                message: null,
                error: null,
                component: "",
            };
        case UPDATE_PASSWORD_SUCCESS:
            Cookies.remove("staff");
            localStorage.clear();
            API.defaults.headers.common["Authorization"] = `Bearer `;
            return {
                ...state,
                loading: false,
                user: {
                    authenticated: false,
                    isRequiredChangePassword: false,
                    permissions: []
                },
                isRequiredActivation: false,
                message: "Your Password was updated",
            };
        case UPDATE_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: "",
            };
        case SEND_RESET_PASSWORD_STARTED:
            return {
                ...state,
                loading: true,
                message: null,
                error: null,
            };
        case SEND_RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                message: "A reset password instruction was sent to your email!",
            };
        case SEND_RESET_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                error: "There is an error. Cannot send reset password email!",
            };
        case RESET_PASSWORD_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                message:
                    "Password has been reset successfully. Please log in again!",
            };
        case RESET_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                error: "There is an error. Cannot reset your password!",
            };
        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
            };
        default:
            return { ...state };
    }
}
