import React, { useRef, useState, useEffect } from "react";
import dayjs from "dayjs";

const calculateCoordinate = time => {
    const blockTimeHeight = localStorage.getItem("blockTimeHeight") || 30;
    const intTime = Number(time.toString().replace(/:/g, ""));
    const diffInTime = intTime - 600;
    const diffInPixel =
        Math.floor(diffInTime / 100) * blockTimeHeight * 4 +
        Math.round(diffInTime % 100) * (blockTimeHeight * 4 / 60);
    return diffInPixel;
};

export const CurrentTimeIndicator = (props) => {
    const ref = useRef(null);
    const [currentTime, setCurrentTime] = useState(dayjs().format("HH:mm"));
    const [scrolled, setScrolled] = useState(false)

    useEffect(() => {
        const executeScroll = () => {
            if (ref && ref.current && !scrolled) {
                ref.current.scrollIntoView({
                    block: "center",
                    behavior: "smooth"
                });
                setScrolled(true)
            }
        };
        executeScroll();
        const interval = setInterval(() => {
            setCurrentTime(dayjs().format("HH:mm"));
        }, 30 * 1000);
        return () => clearInterval(interval);
    }, [scrolled]);

    return (
        <div
            className='current-time-indicator'
            ref={ref}
            style={{
                //110 is height of staff header see it in AppointmentCalendar component
                top: calculateCoordinate(currentTime) + "px",
                width: "100%",
                position: "absolute",
                textAlign: "left",
            }}
        >
            <span className='current-time-text' style={{ backgroundColor: "#ff7875", borderRadius: "3px", color: "#fff", fontWeight: "bold" }}>{dayjs(currentTime, "HH:mm").format("hh:mma")}</span>
        </div>
    );
};
