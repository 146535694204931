import { Button, ErrorBlock } from 'antd-mobile'
import React from 'react'
import { useNavigate } from 'react-router'

const Error404 = () => {

    const navigate = useNavigate()

    return (
        <ErrorBlock
            status='empty'
            fullPage
            title='404'
            description={
                <div>
                    <p>Sorry, the page you visited does not exist.</p>
                    <Button color="primary" onClick={() => navigate('/')}>Back Home</Button>
                </div>
            }
        />
    )
}

export default Error404