import {
    GET_CALENDAR_APPOINTMENTS_STARTED,
    GET_CALENDAR_APPOINTMENTS_SUCCESS,
    GET_CALENDAR_APPOINTMENTS_FAILURE,
    REMOVE_ERROR_MESSAGE
} from "../../constants/calendar/calendar";

const initialState = {
    appointments: [],
    loading: false,
    error: null,
    message: null,
};

export default function calendar(state = initialState, action) {
    switch (action.type) {

        case GET_CALENDAR_APPOINTMENTS_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };

        case GET_CALENDAR_APPOINTMENTS_SUCCESS:
            return {
                ...state,
                appointments: action.payload.appointments,
                error: null,
                loading: false,
            };

        case GET_CALENDAR_APPOINTMENTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };

        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
            };
        default:
            return { ...state };
    }
}
