import { Button, Form, Input, NavBar } from 'antd-mobile'
import { EyeInvisibleOutline, EyeOutline } from 'antd-mobile-icons'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MessageError } from '../../component/Common/MessageError/MessageError'
import { resetPassword } from '../../redux/actions/authenticate/authenticate'
import queryString from 'query-string'
import { useLocation, useNavigate } from 'react-router'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

const ResetPassword = () => {

    const { executeRecaptcha } = useGoogleReCaptcha();
    const { loading, error, message } = useSelector(state => state.authenticate)
    const dispatch = useDispatch()
    const [visible, setVisible] = useState(false)
    const [form] = Form.useForm()
    const location = useLocation()
    const navigate = useNavigate()

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log("Execute recaptcha not yet available");
            return;
        }

        return await executeRecaptcha();
    }, [executeRecaptcha]);

    const onFinish = async (values) => {
        const { token } = queryString.parse(location.search)
        const tokenReCaptcha = await handleReCaptchaVerify();
        dispatch(resetPassword(token, values.newPassword, tokenReCaptcha))
    }

    useEffect(() => {
        MessageError(message, error)
        if (message) {
            navigate('/')
        }
    }, [error, message, navigate])

    return (
        <>
            <div className="top-nav"  >
                <NavBar back={null}> <div style={{ textAlign: "center", marginTop: "15px" }}>
                    {/* <img src='dash-logo-long.png' alt="Logo" height="50px" /> */}
                    <h2 style={{ color: "#1677ff" }}>Dash Booking</h2>
                </div></NavBar>
            </div>
            <h2 style={{ marginTop: "10%" }}>Reset Password</h2>
            <Form
                form={form}
                style={{ textAlign: 'left' }}
                // layout='horizontal'
                onFinish={onFinish}
                mode="card"
                footer={
                    <Button block type='submit' color='primary' loading={loading} size='large' shape='rounded'>
                        Reset Password
                    </Button>
                }
            >
                <Form.Item
                    name='newPassword'
                    label='New Password'
                    rules={[
                        { required: true, message: 'New Password is required!' },
                        { pattern: /^(?=.*[A-Z])(?=.*\d).{6,}$/, message: "Password must contain at least 6 characters long and contains at least 1 uppercase letter, 1 lowercase letter and 1 number" }
                    ]}
                    extra={
                        <div >
                            {!visible ? (
                                <EyeInvisibleOutline onClick={() => setVisible(true)} />
                            ) : (
                                <EyeOutline onClick={() => setVisible(false)} />
                            )}
                        </div>
                    }
                >
                    <Input
                        placeholder='New Password'
                        clearable
                        type={visible ? 'text' : 'password'}
                    />
                </Form.Item>
                <Form.Item
                    name='reTypeNewPassword'
                    label='Re-type new password'
                    rules={[
                        { required: true, message: 'Password is required!' },
                        {
                            validator: (_, value) => {
                                const newPassword = form.getFieldValue('newPassword');
                                if (value && value !== newPassword) {
                                    return Promise.reject('Passwords do not match!');
                                }
                                return Promise.resolve();
                            }
                        }
                    ]}
                    extra={
                        <div >
                            {!visible ? (
                                <EyeInvisibleOutline onClick={() => setVisible(true)} />
                            ) : (
                                <EyeOutline onClick={() => setVisible(false)} />
                            )}
                        </div>
                    }
                >

                    <Input
                        placeholder='Re-type new password'
                        clearable
                        type={visible ? 'text' : 'password'}
                    />



                </Form.Item>

            </Form >
            <a className="login-form-forgot" href="/">
                Back To Login
            </a>
        </>
    )
}

export default ResetPassword