import {
    GET_APPOINTMENTS_STARTED,
    GET_APPOINTMENTS_SUCCESS,
    GET_APPOINTMENTS_FAILURE,
    GET_APPOINTMENT_DETAIL_STARTED,
    GET_APPOINTMENT_DETAIL_SUCCESS,
    GET_APPOINTMENT_DETAIL_FAILURE,
    REMOVE_ERROR_MESSAGE
} from "../../constants/appointment/appointment";
import { LOGOUT } from "../../constants/authenticate/authenticate";

import API from "../../../utils/API";

export const getAppointmentDetail = (aid) => {
    return (dispatch) => {
        dispatch(getAppointmentDetailStarted());
        API.get(`/api/appointments/get-appointment-detail?appointmentId=${aid}`)
            .then((res) => {
                dispatch(getAppointmentDetailSuccess(res.data));
            })
            .catch((err) => {
                catchError(err, dispatch, getAppointmentDetailFailure)
            }).finally(() => {
                setTimeout(function () {
                    dispatch(removeErrorMessage());
                }, 1000);
            });
    };
}

const getAppointmentDetailStarted = () => ({
    type: GET_APPOINTMENT_DETAIL_STARTED,
});

const getAppointmentDetailSuccess = (data) => ({
    type: GET_APPOINTMENT_DETAIL_SUCCESS,
    payload: {
        appointmentDetail: data
    },
});

const getAppointmentDetailFailure = (error) => ({
    type: GET_APPOINTMENT_DETAIL_FAILURE,
    payload: { error },
});

export const getAppointments = (date, timeZone) => {
    return (dispatch) => {
        dispatch(getAppointmentsStarted());
        API.get(`/api/appointments/get-appointments?date=${date}&timezone=${timeZone}`)
            .then((res) => {
                dispatch(getAppointmentsSuccess(res.data));
            })
            .catch((err) => {
                catchError(err, dispatch, getAppointmentsFailure)
            }).finally(() => {
                setTimeout(function () {
                    dispatch(removeErrorMessage());
                }, 1000);
            });
    };
}

const getAppointmentsStarted = () => ({
    type: GET_APPOINTMENTS_STARTED,
});

const getAppointmentsSuccess = (data) => ({
    type: GET_APPOINTMENTS_SUCCESS,
    payload: {
        appointments: data.appointments,
        staff: data.staff,
        staffHour: data.staffHour,
        holidayAndSpecialHour: data.holidayAndSpecialHour
    },
});

const getAppointmentsFailure = (error) => ({
    type: GET_APPOINTMENTS_FAILURE,
    payload: { error },
});

const logoutUser = () => ({
    type: LOGOUT
})


const removeErrorMessage = () => ({
    type: REMOVE_ERROR_MESSAGE,
})


//Handle catch error
//logout use when unauthorized
//display error message and remove message after 3 seconds
const catchError = (error, dispatch, nextFunction) => {
    var message = error.message
    if (error.response) {
        message = error.response.data.message
        if (error.response.status === 401) {
            dispatch(logoutUser());
        }
    }

    dispatch(nextFunction(message));
};